import { createAction, NotAllowedCheck, props } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';
import { IWorkbenchParameterModel } from '../../features/workbench/models/workbench-parameter.model';
import { IApplicationMenuItemModel } from '../../shared/models/application-menu-item.model';
import { IBoard } from '../models/board.model';
import { IDisplayMetric } from '../models/display-metric.model';
import { IFilterData } from '../models/filter-data.model';
import { ISavedBoard } from '../models/saved-board.model';
import { IProductDisplayAttributeMetric } from '../models/product-display-attribute-metric.model';
import { IStyleParametersModel } from '../../features/workbench/models/style-parameters.model';
import { IStyleTableRow } from '../../features/workbench/models/style-table-row.model';
import { IUserRole } from '../models/user-roles.model';
import { ListingDisplayMode } from '../models/display-mode.enum';

export const actionStoreUserDetails: ActionCreator<
    '[Store] Set User Details',
    (
        props: {
            userDetails: { email: string; roles: Array<IUserRole> } | undefined;
        } & NotAllowedCheck<{ userDetails: { email: string; roles: Array<IUserRole> } | undefined }>
    ) => {
        userDetails: { email: string; roles: Array<IUserRole> } | undefined;
    } & TypedAction<'[Store] Set User Details'>
> = createAction('[Store] Set User Details', props<{ userDetails: { email: string; roles: Array<IUserRole> } | undefined }>());

export const actionStoreSetSavedBoards: ActionCreator<
    '[Store] Set Saved Boards',
    (
        props: {
            savedBoards: ISavedBoard;
        } & NotAllowedCheck<{
            savedBoards: ISavedBoard;
        }>
    ) => {
        savedBoards: ISavedBoard;
    } & TypedAction<'[Store] Set Saved Boards'>
> = createAction(
    '[Store] Set Saved Boards',
    props<{
        savedBoards: ISavedBoard;
    }>()
);

export const actionStoreApplyFilters: ActionCreator<
    '[Store] Apply Filters',
    (
        props: {
            boardData: IBoard;
            filterData: IFilterData;
        } & NotAllowedCheck<{ boardData: IBoard; filterData: IFilterData }>
    ) => {
        boardData: IBoard;
        filterData: IFilterData;
    } & TypedAction<'[Store] Apply Filters'>
> = createAction('[Store] Apply Filters', props<{ boardData: IBoard; filterData: IFilterData }>());

export const actionStoreSetFilterSaved: ActionCreator<
    '[Store] Set Filters Saved',
    (
        props: {
            filtersSaved: boolean;
        } & NotAllowedCheck<{
            filtersSaved: boolean;
        }>
    ) => {
        filtersSaved: boolean;
    } & TypedAction<'[Store] Set Filters Saved'>
> = createAction(
    '[Store] Set Filters Saved',
    props<{
        filtersSaved: boolean;
    }>()
);

export const actionStoreSetGallery: ActionCreator<
    '[Store] Set Gallery',
    (
        props: {
            gallery: ListingDisplayMode;
        } & NotAllowedCheck<{
            gallery: ListingDisplayMode;
        }>
    ) => {
        gallery: ListingDisplayMode;
    } & TypedAction<'[Store] Set Gallery'>
> = createAction(
    '[Store] Set Gallery',
    props<{
        gallery: ListingDisplayMode;
    }>()
);

export const actionStoreSetExclusionsSaved: ActionCreator<
    '[Store] Set Exclusions Saved',
    (
        props: {
            exclusionsSaved: boolean;
        } & NotAllowedCheck<{
            exclusionsSaved: boolean;
        }>
    ) => {
        exclusionsSaved: boolean;
    } & TypedAction<'[Store] Set Exclusions Saved'>
> = createAction(
    '[Store] Set Exclusions Saved',
    props<{
        exclusionsSaved: boolean;
    }>()
);

export const actionStoreSetColumnOrder: ActionCreator<
    '[Store] Set Column Order',
    (
        props: {
            orderedColumns: Array<IProductDisplayAttributeMetric>;
        } & NotAllowedCheck<{
            orderedColumns: Array<IProductDisplayAttributeMetric>;
        }>
    ) => {
        orderedColumns: Array<IProductDisplayAttributeMetric>;
    } & TypedAction<'[Store] Set Column Order'>
> = createAction('[Store] Set Column Order', props<{ orderedColumns: Array<IProductDisplayAttributeMetric> }>());

export const actionStoreSetActiveApplication: ActionCreator<
    '[Store] Set Active Application',
    (
        props: {
            activeApplication: IApplicationMenuItemModel;
        } & NotAllowedCheck<{
            activeApplication: IApplicationMenuItemModel;
        }>
    ) => {
        activeApplication: IApplicationMenuItemModel;
    } & TypedAction<'[Store] Set Active Application'>
> = createAction('[Store] Set Active Application', props<{ activeApplication: IApplicationMenuItemModel }>());

export const actionStoreSetAttributeTableSort: ActionCreator<
    '[Store] Set Attribute Table Sort',
    (
        props: {
            sortDirection: 'asc' | 'desc';
            sortOrder: string;
        } & NotAllowedCheck<{
            sortDirection: 'asc' | 'desc';
            sortOrder: string;
        }>
    ) => {
        sortDirection: 'asc' | 'desc';
        sortOrder: string;
    } & TypedAction<'[Store] Set Attribute Table Sort'>
> = createAction('[Store] Set Attribute Table Sort', props<{ sortDirection: 'asc' | 'desc'; sortOrder: string }>());

export const actionStoreSetAttributeDisplayMetrics: ActionCreator<
    '[Store] Set Attribute Display Metrics',
    (
        props: {
            displayMetrics: Array<IProductDisplayAttributeMetric>;
        } & NotAllowedCheck<{
            displayMetrics: Array<IProductDisplayAttributeMetric>;
        }>
    ) => {
        displayMetrics: Array<IProductDisplayAttributeMetric>;
    } & TypedAction<'[Store] Set Attribute Display Metrics'>
> = createAction('[Store] Set Attribute Display Metrics', props<{ displayMetrics: Array<IProductDisplayAttributeMetric> }>());

export const actionStoreSetWorkbenchSortOrder: ActionCreator<
    '[Store] Set Workbench Sort Order',
    (
        props: {
            sortOrder: string;
        } & NotAllowedCheck<{
            sortOrder: string;
        }>
    ) => {
        sortOrder: string;
    } & TypedAction<'[Store] Set Workbench Sort Order'>
> = createAction(
    '[Store] Set Workbench Sort Order',
    props<{
        sortOrder: string;
    }>()
);
export const actionStoreSetStylingSortOrder: ActionCreator<
    '[Store] Set Styling Sort Order',
    (
        props: {
            sortOrder: string;
        } & NotAllowedCheck<{
            sortOrder: string;
        }>
    ) => {
        sortOrder: string;
    } & TypedAction<'[Store] Set Styling Sort Order'>
> = createAction(
    '[Store] Set Styling Sort Order',
    props<{
        sortOrder: string;
    }>()
);
export const actionStoreSetWorkbenchSortDirection: ActionCreator<
    '[Store] Set Workbench Sort Direction',
    (
        props: {
            sortDirection: 'asc' | 'desc';
        } & NotAllowedCheck<{
            sortDirection: 'asc' | 'desc';
        }>
    ) => {
        sortDirection: 'asc' | 'desc';
    } & TypedAction<'[Store] Set Workbench Sort Direction'>
> = createAction(
    '[Store] Set Workbench Sort Direction',
    props<{
        sortDirection: 'asc' | 'desc';
    }>()
);
export const actionStoreSetStylingSortDirection: ActionCreator<
    '[Store] Set Styling Sort Direction',
    (
        props: {
            sortDirection: 'asc' | 'desc';
        } & NotAllowedCheck<{
            sortDirection: 'asc' | 'desc';
        }>
    ) => {
        sortDirection: 'asc' | 'desc';
    } & TypedAction<'[Store] Set Styling Sort Direction'>
> = createAction(
    '[Store] Set Styling Sort Direction',
    props<{
        sortDirection: 'asc' | 'desc';
    }>()
);
export const actionStoreSetWorkbenchSort: ActionCreator<
    '[Store] Set Workbench Sort',
    (
        props: {
            sortDirection: 'asc' | 'desc';
            sortOrder: string;
        } & NotAllowedCheck<{
            sortDirection: 'asc' | 'desc';
            sortOrder: string;
        }>
    ) => {
        sortDirection: 'asc' | 'desc';
        sortOrder: string;
    } & TypedAction<'[Store] Set Workbench Sort'>
> = createAction(
    '[Store] Set Workbench Sort',
    props<{
        sortDirection: 'asc' | 'desc';
        sortOrder: string;
    }>()
);
export const actionStoreSetStylingSort: ActionCreator<
    '[Store] Set Styling Sort',
    (
        props: {
            sortDirection: 'asc' | 'desc';
            sortOrder: string;
        } & NotAllowedCheck<{
            sortDirection: 'asc' | 'desc';
            sortOrder: string;
        }>
    ) => {
        sortDirection: 'asc' | 'desc';
        sortOrder: string;
    } & TypedAction<'[Store] Set Styling Sort'>
> = createAction(
    '[Store] Set Styling Sort',
    props<{
        sortDirection: 'asc' | 'desc';
        sortOrder: string;
    }>()
);
export const actionStoreSetWorkbenchDisplayMetrics: ActionCreator<
    '[Store] Set Workbench Display Metrics',
    (
        props: {
            displayMetrics: Array<IDisplayMetric>;
        } & NotAllowedCheck<{
            displayMetrics: Array<IDisplayMetric>;
        }>
    ) => {
        displayMetrics: Array<IDisplayMetric>;
    } & TypedAction<'[Store] Set Workbench Display Metrics'>
> = createAction('[Store] Set Workbench Display Metrics', props<{ displayMetrics: Array<IDisplayMetric> }>());

export const actionStoreSetWorkbenchDisplayMetricsDefaults: ActionCreator<
    '[Store] Set Workbench Display Metrics Defaults',
    (
        props: {
            displayMetrics: Array<IDisplayMetric>;
        } & NotAllowedCheck<{
            displayMetrics: Array<IDisplayMetric>;
        }>
    ) => {
        displayMetrics: Array<IDisplayMetric>;
    } & TypedAction<'[Store] Set Workbench Display Metrics Defaults'>
> = createAction('[Store] Set Workbench Display Metrics Defaults', props<{ displayMetrics: Array<IDisplayMetric> }>());

export const actionStoreSetStylingDisplayMetrics: ActionCreator<
    '[Store] Set Styling Display Metrics',
    (
        props: {
            displayMetrics: Array<IDisplayMetric>;
        } & NotAllowedCheck<{
            displayMetrics: Array<IDisplayMetric>;
        }>
    ) => {
        displayMetrics: Array<IDisplayMetric>;
    } & TypedAction<'[Store] Set Styling Display Metrics'>
> = createAction(
    '[Store] Set Styling Display Metrics',
    props<{
        displayMetrics: Array<IDisplayMetric>;
    }>()
);
export const actionStoreClearWorkbench: ActionCreator<'[Store] Clear Workbench', () => TypedAction<'[Store] Clear Workbench'>> =
    createAction('[Store] Clear Workbench');
export const actionStoreClearAllWorkbench: ActionCreator<
    '[Store] Clear All Workbench',
    () => TypedAction<'[Store] Clear All Workbench'>
> = createAction('[Store] Clear All Workbench');
export const actionStoreClearAllAnalytics: ActionCreator<
    '[Store] Clear All Analytics',
    () => TypedAction<'[Store] Clear All Analytics'>
> = createAction('[Store] Clear All Analytics');
export const actionStoreWorkbenchFilterData: ActionCreator<
    '[Store] Set Workbench Filter Data',
    (
        props: {
            filterData: IFilterData;
        } & NotAllowedCheck<{
            filterData: IFilterData;
        }>
    ) => {
        filterData: IFilterData;
    } & TypedAction<'[Store] Set Workbench Filter Data'>
> = createAction('[Store] Set Workbench Filter Data', props<{ filterData: IFilterData }>());

export const actionStoreSetAnalyticsFilterData: ActionCreator<
    '[Store] Set Analytics Filter Data',
    (
        props: {
            filterData: IFilterData;
        } & NotAllowedCheck<{
            filterData: IFilterData;
        }>
    ) => {
        filterData: IFilterData;
    } & TypedAction<'[Store] Set Analytics Filter Data'>
> = createAction('[Store] Set Analytics Filter Data', props<{ filterData: IFilterData }>());
export const actionStoreWorkbenchSearchTerm: ActionCreator<
    '[Store] Set Workbench Search Term',
    (
        props: {
            searchTerm: string;
        } & NotAllowedCheck<{
            searchTerm: string;
        }>
    ) => {
        searchTerm: string;
    } & TypedAction<'[Store] Set Workbench Search Term'>
> = createAction(
    '[Store] Set Workbench Search Term',
    props<{
        searchTerm: string;
    }>()
);
export const actionStoreSetStylings: ActionCreator<
    '[Store] Set Stylings',
    (
        props: {
            stylings: Array<string>;
        } & NotAllowedCheck<{
            stylings: Array<string>;
        }>
    ) => {
        stylings: Array<string>;
    } & TypedAction<'[Store] Set Stylings'>
> = createAction(
    '[Store] Set Stylings',
    props<{
        stylings: Array<string>;
    }>()
);

export const actionStoreUpdateStyleParameters: ActionCreator<
    '[Store] Update Style Parameters',
    (
        props: {
            parameters: IStyleParametersModel;
        } & NotAllowedCheck<{
            parameters: IStyleParametersModel;
        }>
    ) => {
        parameters: IStyleParametersModel;
    } & TypedAction<'[Store] Update Style Parameters'>
> = createAction(
    '[Store] Update Style Parameters',
    props<{
        parameters: IStyleParametersModel;
    }>()
);
export const actionStoreUpdateWorkbenchParameters: ActionCreator<
    '[Store] Update Workbench Parameters',
    (
        props: {
            parameters: IWorkbenchParameterModel;
        } & NotAllowedCheck<{
            parameters: IWorkbenchParameterModel;
        }>
    ) => {
        parameters: IWorkbenchParameterModel;
    } & TypedAction<'[Store] Update Workbench Parameters'>
> = createAction(
    '[Store] Update Workbench Parameters',
    props<{
        parameters: IWorkbenchParameterModel;
    }>()
);
export const actionStoreUpdateStyleTable: ActionCreator<
    '[Store] Update Style table',
    (
        props: {
            styles: Array<IStyleTableRow>;
        } & NotAllowedCheck<{
            styles: Array<IStyleTableRow>;
        }>
    ) => {
        styles: Array<IStyleTableRow>;
    } & TypedAction<'[Store] Update Style table'>
> = createAction(
    '[Store] Update Style table',
    props<{
        styles: Array<IStyleTableRow>;
    }>()
);

export const actionStoreSetWorkbenchMetaDataFilter: ActionCreator<
    '[Store] Set Workbench Meta Data Filter',
    (
        props: {
            name: string;
            value: string;
        } & NotAllowedCheck<{
            name: string;
            value: string;
        }>
    ) => {
        name: string;
        value: string;
    } & TypedAction<'[Store] Set Workbench Meta Data Filter'>
> = createAction('[Store] Set Workbench Meta Data Filter', props<{ name: string; value: string }>());
