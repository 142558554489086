import { Component, Input } from '@angular/core';
import { TextComponent } from '../../../../elements';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    selector: 'pt-ui-section-header',
    templateUrl: './section-header.component.html',
    styleUrls: ['./section-header.component.scss'],
    imports: [TextComponent, CommonModule]
})
export class SectionHeaderComponent {
    @Input() public label: string = '';
    @Input() public options: string = '';

    public styling = {
        wrapper: () => ({
            wrapper: true,
            'wrapper--selectable': this.options
        })
    };

    public content = {
        options: () => {
            const result = this.options.split(',').map(item => item.trim());
            return result;
        }
    };
}
