import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent, IconComponent } from '../../../../elements';

@Component({
    standalone: true,
    selector: 'pt-ui-level-item',
    templateUrl: './level-item.component.html',
    styleUrls: ['./level-item.component.scss'],
    imports: [TextComponent, CommonModule, IconComponent]
})
export class LevelItemComponent {
    @Input() public checked: boolean | null = null;
    @Input() public title: string = '';
    @Input() public open: boolean | null = null;
    @Input() public count: number | null = null;
    @Input() public label: string = '';

    public styling = {
        wrapper: () => ({
            wrapper: true,
            'wrapper--selectable':
                typeof this.open === 'boolean' || (typeof this.count !== 'number' && typeof this.checked === 'boolean')
        }),
        open: () => ({
            open: true,
            'open--active': this.open
        })
    };

    public content = {
        checkbox: () => typeof this.count !== 'number' && typeof this.checked === 'boolean',
        indicator: () => typeof this.checked === 'boolean' || typeof this.count === 'number',
        count: () => typeof this.count === 'number',
        open: () => typeof this.open === 'boolean',
        label: () => {
            const array = this.label.split(',').map(item => item.trim());
            return array;
        }
    };
}
