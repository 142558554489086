<div [ngClass]="styling.wrapper()" *ngIf="label && options">
    <pt-ui-text class="overlay" size="s" [compact]="true" format="bold">Change level</pt-ui-text>

    <select class="select">
        <option *ngFor="let item of content.options()" class="option" [value]="item" selected="item === title">
            {{ item }}
        </option>
    </select>
</div>

<div [ngClass]="styling.wrapper()" *ngIf="!label || !options">
    <div class="select">{{ label }}</div>
</div>
