import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../core/core.state';
import { actionStoreSetStylingDisplayMetrics,
          actionStoreSetWorkbenchDisplayMetricsDefaults
} from '../../core/store/store.actions';

@Injectable({
  providedIn: 'root'
})
export class DefaultMetricService {

  public constructor(private readonly _store: Store<IAppState>) {
  }

  public async hydrateWorkbenchDefaults(): Promise<void> {
    this._store.dispatch(
      actionStoreSetWorkbenchDisplayMetricsDefaults({
        displayMetrics: [
          // Usual Display Metrics
          // {
          //   metric_name: 'POC_ind',
          //   metric_acronym: 'POC Style',
          //   metric_description: 'POC style'
          // },
          // {
          //   metric_name: 'product_store_grid',
          //   metric_acronym: 'Str Grid',
          //   metric_description: 'Store Grid'
          // },
          // {
          //   metric_name: 'wh_soh',
          //   metric_acronym: 'DC SOH',
          //   metric_description: 'DC Stock On Hand Units'
          // },
          // {
          //   metric_name: 'st_soh',
          //   metric_acronym: 'Str SOH',
          //   metric_description: 'Store Stock On Hand Units'
          // },
          // {
          //   metric_name: 'l9_wk_sls_u',
          //   metric_acronym: 'Sls U 9wk',
          //   metric_description: 'Sales Units Last 9 Weeks'
          // },
          // {
          //   metric_name: 'l9wk_aws',
          //   metric_acronym: 'AWS U 9wk',
          //   metric_description: 'Average Weekly Sales Units Last 9 Weeks (Weighted)'
          // },
          // {
          //   metric_name: 'ml8wk_need',
          //   metric_acronym: 'ML Need 8wk',
          //   metric_description: 'ML Generated Gross Need (Demand) for next 8 Weeks'
          // },
          // {
          //   metric_name: 'ml8wk_pnn',
          //   metric_acronym: 'ML PNN 8wk',
          //   metric_description: 'ML Generated Positive Net Need for next 8 Weeks'
          // },
          // {
          //   metric_name: 'allocated',
          //   metric_acronym: 'ML Allocated',
          //   metric_description: 'ML auto select method value'
          // },
          // {
          //   metric_name: 'allocation_method',
          //   metric_acronym: 'ML Allocated Method',
          //   metric_description: 'ML auto selected method'
          // }

          // BRD Display Metrics
          // {
          //   metric_name: '',
          //   metric_acronym: 'Job No',
          //   metric_description: ''
          // },
          {
            metric_name: 'wh_on_order',
            metric_acronym: 'DC On Order',
            metric_description: 'DC On Order Units'
          },
          {
            metric_name: 'wh_soh',
            metric_acronym: 'DC SOH',
            metric_description: 'DC Stock On Hand Units'
          },
          {
            metric_name: 'st_soh',
            metric_acronym: 'Str SOH',
            metric_description: 'Store Stock On Hand Units'
          },
          // {
          //   metric_name: '',
          //   metric_acronym: 'Store Wks',
          //   metric_description: ''
          // },
          {
            metric_name: 'l9wk_aws',
            metric_acronym: 'AWS U 9wk',
            metric_description: 'Average Weekly Sales Units Last 9 Weeks (Weighted)'
          },
          // {
          //   metric_name: '',
          //   metric_acronym: 'Plan Otp',
          //   metric_description: ''
          // },
          {
            metric_name: 'category_store_cover',
            metric_acronym: 'Ideal WOC',
            metric_description: 'Ideal Weeks Of Cover'
          },
          {
            metric_name: 'sls_flow_multiplier',
            metric_acronym: 'Sls Flow Fac (LY)',
            metric_description: 'Sales Flow Factor (Last Year)'
          },
          {
            metric_name: 'algo_need',
            metric_acronym: 'Algo Need',
            metric_description: 'Pattern Algorithm Gross Need (Demand)'
          },
          {
            metric_name: 'algo_nn',
            metric_acronym: 'Algo NN',
            metric_description: 'Pattern Algorithm Net Need (Need - SOH)'
          },
          {
            metric_name: 'algo_pnn',
            metric_acronym: 'Algo PNN',
            metric_description: 'Pattern Algorithm Positive Net Need'
          }
          // Not in the table. Is Hoosain adding this too or was it a typo?
          // {
          //   metric_name: '',
          //   metric_acronym: 'Alloc U',
          //   metric_description: ''
          // }
        ]
      })
    );
  }

  public async hydrateStylingDefaults(): Promise<void> {
    this._store.dispatch(
        actionStoreSetStylingDisplayMetrics({
          displayMetrics: [
            // Usual Display Metrics
            // {
            //   metric_name: 'store_grid_ranged_ind',
            //   metric_acronym: 'Str Ranged',
            //   metric_description: 'Store range indicator'
            // },
            // {
            //   metric_name: 'st_soh',
            //   metric_acronym: 'Str SOH',
            //   metric_description: 'Store Stock On Hand Units'
            // },
            // {
            //   metric_name: 'l9_wk_sls_u',
            //   metric_acronym: 'Sls U 9wk',
            //   metric_description: 'Sales Units Last 9 Weeks'
            // },
            // {
            //   metric_name: 'l9wk_aws',
            //   metric_acronym: 'AWS U 9wk',
            //   metric_description: 'Average Weekly Sales Units Last 9 Weeks (Weighted)'
            // },
            // {
            //   metric_name: 'ml8wk_need',
            //   metric_acronym: 'ML Need 8wk',
            //   metric_description: 'ML Generated Gross Need (Demand) for next 8 Weeks'
            // },
            // {
            //   metric_name: 'ml8wk_pnn',
            //   metric_acronym: 'ML PNN 8wk',
            //   metric_description: 'ML Generated Positive Net Need for next 8 Weeks'
            // }

            // BRD Display Metrics
            {
              metric_name: 'store_grid_ranged_ind',
              metric_acronym: 'Str Ranged',
              metric_description: 'Store range indicator'
            },
            {
              metric_name: 'st_soh',
              metric_acronym: 'Str SOH',
              metric_description: 'Store Stock On Hand Units'
            },
            {
              metric_name: 'l9wk_aws',
              metric_acronym: 'AWS U 9wk',
              metric_description: 'Average Weekly Sales Units Last 9 Weeks (Weighted)'
            },
            // {
            //   metric_name: '',
            //   metric_acronym: 'Plan Otp',
            //   metric_description: ''
            // },
            {
              metric_name: 'sls_flow_multiplier',
              metric_acronym: 'Sls Flow Fac (LY)',
              metric_description: 'Sales Flow Factor (Last Year)'
            },
            {
              metric_name: 'algo_need',
              metric_acronym: 'Algo Need',
              metric_description: 'Pattern Algorithm Gross Need (Demand)'
            },
            {
              metric_name: 'algo_pnn',
              metric_acronym: 'Algo PNN',
              metric_description: 'Pattern Algorithm Positive Net Need'
            }
          ]
        })
    );
  }
}
