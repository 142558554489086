import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { IAppState } from '../../core/core.state';
import { AppConfigService } from '../../core/data/app-config.service';
import { IFilterData } from '../../core/models/filter-data.model';
import { actionStoreSetAnalyticsFilterData, actionStoreWorkbenchFilterData } from '../../core/store/store.actions';

@Injectable({
    providedIn: 'root'
})
export class DefaultFiltersService {
    private readonly apiUrl: string = this._appConfigService.apiUrl;
    private readonly baseUrl: string = '/api/defaultfilters';

    private _cachedAnalyticsDefaults: IFilterData;
    private _cachedAllocationsDefaults: IFilterData;

    public constructor(private readonly _store: Store<IAppState>,
                       private readonly _http: HttpClient,
                       private readonly _appConfigService: AppConfigService) {
    }

    public hasCachedAnalyticsDefaults(): boolean{
        return !!this._cachedAnalyticsDefaults;
    }

    public async getAnalyticsDefaults(): Promise<IFilterData> {
        if (this._cachedAnalyticsDefaults) {
            return this._cachedAnalyticsDefaults;
        }
        this._cachedAnalyticsDefaults = await firstValueFrom(this._http.get<IFilterData>(
            `${this.apiUrl + this.baseUrl}?applicationId=1&userId=DEFAULT`));
        return this._cachedAnalyticsDefaults;
    }

    public async getAllocationsDefaults(): Promise<IFilterData> {
        if (this._cachedAllocationsDefaults) {
            return this._cachedAllocationsDefaults;
        }
        this._cachedAllocationsDefaults = await firstValueFrom(this._http.get<IFilterData>(
            `${this.apiUrl + this.baseUrl}?applicationId=2&userId=DEFAULT`));
        return this._cachedAllocationsDefaults;
    }

    public async hydrateAnalyticsDefaults(): Promise<void> {
        const data: IFilterData = await this.getAnalyticsDefaults();
        this._store.dispatch(actionStoreSetAnalyticsFilterData({ filterData: data }));
    }

    public async hydrateAllocationsDefaults(): Promise<void> {
        const data: IFilterData = await this.getAllocationsDefaults();
        this._store.dispatch(actionStoreWorkbenchFilterData({ filterData: data }));
    }
}
