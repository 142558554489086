import packageJson from '../../../../package.json';

export const environment: {
    appName: string;
    test: boolean;
    debug: boolean;
    attributes: boolean,
    apiUrl: string;
    production: boolean;
    listingPagination: boolean;
    envName: string;
    version: string;
    userManagementApiUrl: string;
    pictureManagerApiUrl: string;
    awsCognitoIdentityPoolId: string;
    awsUserPoolsId: string;
    awsUserPoolsWebClientId: string;
    pictureManagerCloudfrontDistribution: string;
    packlotEnabled: boolean;
} = {
    appName: "Lynkd Pattern Analytics",
    envName: 'PROD',
    debug: false,
    production: true,
    test: false,
    attributes: true,
    listingPagination: false,
    version: packageJson.version,
    apiUrl: 'https://wyfqk1saaj.execute-api.af-south-1.amazonaws.com/Prod',
    userManagementApiUrl: 'https://60bwq5zv45.execute-api.af-south-1.amazonaws.com/dev',
    pictureManagerApiUrl: '',
    awsCognitoIdentityPoolId: 'eu-west-1:cbdf0d51-3723-49ca-8428-cfa1d6775e46',
    awsUserPoolsId: 'eu-west-1_lBhD8B9eB',
    awsUserPoolsWebClientId: '11h0o9n58j7h6ln6352ugmp5hk',
    pictureManagerCloudfrontDistribution: '',
    packlotEnabled: true
};
