<div class="wrapper">
    <div class="backdrop" (click)="onDismiss()"></div>

    <dialog class="content" open>
        <div class="header">
            <ng-content select="[modal-header]"></ng-content>
        </div>

        <div class="inner">
            <ng-content select="[modal-content]"></ng-content>
        </div>

        <div class="footer">
            <ng-content select="[modal-footer]"></ng-content>
        </div>
    </dialog>
</div>
