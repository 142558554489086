import { CommonModule } from '@angular/common';
import { Component, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { ButtonComponent } from '../button';
import { TextComponent } from '../text';

@Component({
    standalone: true,
    selector: 'pt-ui-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    imports: [CommonModule, ButtonComponent, TextComponent]
})
export class ModalComponent {
    @Input() public label: string = '';
    @Output() public readonly dismiss = new EventEmitter<void>();

    public onDismiss(): void {
        this.dismiss.emit();
    }

    @HostListener('document:keyup', ['$event']) public onKeyUp(event: KeyboardEvent): void {
        if (event.key === 'Escape') this.onDismiss();
    }
}
