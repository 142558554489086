<label [ngClass]="styling.wrapper()">
    <input *ngIf="content.checkbox()" type="checkbox" class="input" [checked]="checked" />

    <div class="indicator" *ngIf="content.indicator()">
        <pt-ui-icon variant="check" class="check" size="m"></pt-ui-icon>
        <pt-ui-text *ngIf="content.count()" size="xs" format="bold">{{ count === 0 ? '' : count }}</pt-ui-text>
    </div>

    <div class="label">
        <pt-ui-text *ngIf="content.label()[0] === ''" size="s" background="dark" [compact]="true" loading="4"></pt-ui-text>

        <ng-container *ngFor="let item of content.label(); let index = index">
            <pt-ui-text size="s" background="dark" [compact]="true">{{ item }}</pt-ui-text>

            <pt-ui-text
                size="s"
                *ngIf="index + 1 !== content.label().length"
                background="dark"
                importance="secondary"
                [compact]="true"
            >
                /
            </pt-ui-text>
        </ng-container>
    </div>

    <pt-ui-icon variant="down" [ngClass]="styling.open()" *ngIf="content.open()"></pt-ui-icon>
</label>
