import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'pt-ui-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    imports: [CommonModule]
})
export class IconComponent {
    @Input() public variant: '' | 'check' | 'add' | 'right' | 'left' | 'forward' | 'search' | 'down' = '';
    @Input() public importance: 'primary' | 'secondary' = 'primary';
    @Input() public size: 's' | 'm' | 'l' | 'xl' = 'm';

    public styling = {
        wrapper: () => ({
            wrapper: true,
            'wrapper--secondary': this.importance === 'secondary',
            [`wrapper--${this.size}`]: true
        })
    };
}
