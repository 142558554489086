import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IconComponent } from '../icon';
import { kebabCase } from 'lodash';
import * as h from './button.helpers';

@Component({
    standalone: true,
    selector: 'pt-ui-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    imports: [CommonModule, IconComponent]
})
export class ButtonComponent implements OnInit {
    @Input() public disabled: boolean = false;
    @Input() public loading: boolean = false;
    @Input() public label: string = '';
    @Input() public icon: string = '';
    @Input() public importance: 'primary' | 'secondary' = 'secondary';
    @Input() public strict: boolean;
    @Input() public href: string | undefined = undefined;
    @Input() public submit: string | undefined = undefined;

    public styling = {
        wrapper: () => ({
            wrapper: true,
            'wrapper--primary': this.importance === 'primary',
            'wrapper--loading': this.loading,
            'wrapper--circle': !this.label && Boolean(this.icon),
            'wrapper--disabled': this.disabled || this.loading,
            'wrapper--opacity': this.disabled && !this.loading
        })
    };

    public content = {
        label: () => h.truncate({ value: this.label, amount: 40 })
    };

    public getTestId() {
        return `button-${kebabCase(this.label || this.icon)}`;
    }

    public ngOnInit() {
        if (this.strict !== false && this.label.length > 20) {
            // eslint-disable-next-line no-console
            console.warn('Button label is too long, it will break the layout');
        }
    }
}
