import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextComponent } from '../../../../elements';

const formatter = new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'short'
});

const toZAR = (value: number) => formatter.format(value);

@Component({
    standalone: true,
    selector: 'pt-ui-plan-block',
    templateUrl: './plan-block.component.html',
    styleUrls: ['./plan-block.component.scss'],
    imports: [TextComponent, CommonModule]
})
export class PlanBlockComponent {
    @Input() public season: string | undefined = undefined;
    @Input() public core: string | undefined = undefined;
    @Input() public po: number | undefined = undefined;
    @Input() public rp: number | undefined = undefined;
    @Input() public op: number | undefined = undefined;
    @Input() public name: string | undefined = undefined;
    @Input() public selected: boolean = false;
    @Input() public phase: string = '';

    public calculate = {
        season: () => {
            if (!this.season) return [undefined, undefined];
            return this.season.replace(/\s/g, '').split('/').map(Number);
        },
        core: () => {
            if (!this.core) return [undefined, undefined];
            return this.core.replace(/\s/g, '').split('/').map(Number);
        },
        value: () => this.calculate.core()[0] + this.calculate.season()[0],
        total: () => this.calculate.core()[1] + this.calculate.season()[1],
        hasValues: () => this.season && this.core && this.phase && this.po && this.rp && this.op,
        percentage: () => (!this.calculate.hasValues() ? 0 : (this.calculate.value() / this.calculate.total()) * 100)
    };

    public styling = {
        check: () => ({
            check: true,
            'check--selected': this.selected
        }),
        wrapper: () => ({
            wrapper: true,
            'wrapper--loading': !this.calculate.hasValues()
        }),
        expanded: () => ({
            expanded: true,
            'expanded--active': this.selected
        }),
        progress: () => ({
            width: `${this.calculate.percentage()}%`,
            background: this.calculate.percentage() > 100 ? '#ef233c' : 'rgb(240, 190, 100)'
        })
    };

    public content = {
        progress: () => (!this.calculate.hasValues() ? '' : `${this.calculate.percentage().toFixed(0)}%`),
        cost: () => [
            {
                title: 'Total',
                value: this.calculate.hasValues() && toZAR(this.calculate.value()),
                total: this.calculate.hasValues() && toZAR(this.calculate.total())
            },
            {
                title: 'Core',
                value: this.core && toZAR(this.calculate.core()[0]),
                total: this.core && toZAR(this.calculate.core()[1])
            },
            {
                title: 'Season',
                value: this.season && toZAR(this.calculate.season()[0]),
                total: this.season && toZAR(this.calculate.season()[1])
            }
        ],
        phase: () => [
            {
                title: 'Original Plan (OP)',
                value: this.op
            },
            {
                title: 'Revised Plan (RP)',
                value: this.rp
            },
            {
                title: 'Bought (PO)',
                value: this.po
            }
        ]
    };

    public toggle() {
        if (!this.calculate.hasValues()) return;
        this.selected = !this.selected;
    }
}
